import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TosRoutingModule } from './tos-routing.module';
import { TosComponent } from './tos.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TosComponent],
  imports: [CommonModule, TosRoutingModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TosModule {}
